/**
 * External dependencies.
 */
import { GatsbyBrowser } from "gatsby";
import React from "react";

import { AppProvider } from "./AppProvider";
import { Provider } from "react-redux";
import createStore from "../store";

/**
 * Internal dependencies.
 */

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */
export const WrapWithProvider: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  const store = createStore();
  return <Provider store={store}>{element}</Provider>;
};
