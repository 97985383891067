/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { WrapPageProvider } from "./src/Providers/wrap-page-element";
import { WrapWithProvider } from "./src/Providers/wrap-root-element";

import "./src/styles/global.css";
import "./src/styles/index";

export const wrapRootElement = WrapWithProvider;
export const wrapPageElement = WrapPageProvider;
