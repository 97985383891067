import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { PageTheme, UTMProps } from "../../types";

export type UserState = {
  modal: boolean;
  pageTheme: PageTheme;
  utm?: UTMProps;
};

const initialState: UserState = {
  modal: false,
  pageTheme: "white",
  utm: {
    campaign: "",
    medium: "",
    source: "",
    content: "",
    term: "",
    referrer: "",
  },
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setModal(state, action: PayloadAction<boolean>) {
      state.modal = action.payload;
    },
    setThemeMode(state, action: PayloadAction<PageTheme>) {
      state.pageTheme = action.payload;
    },
    setUtm(state, action: PayloadAction<UTMProps>) {
      state.utm = action.payload;
    },
  },
});

const selectUser = (state: AppState) => state.user;
const userSelectors = {
  user: selectUser,
};

// export { actions, selectors, reducer }
export const { setModal, setThemeMode, setUtm } = slice.actions;
export default slice.reducer;
export { userSelectors };
