import { isBrowser } from "./isBrowser";

export const getUtm = (utm: string) => {
  const url = decodeURI(isBrowser() && window.document.location.search);
  const urlSearch = url.split("&");

  if (urlSearch.length !== 0) {
    let res = urlSearch.filter((url: any) => url.indexOf(utm) !== -1);
    return res.toString().slice(res.toString().lastIndexOf("=") + 1);
  } else {
    return "";
  }
};
